@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif; */
    font-family: "Inter", sans-serif;
    font-weight: "400";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

html {
    box-sizing: border-box;
    /* font-size: 62.5%; */
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body {
    /* height: 100%; */
    margin: 0;
    line-height: 1.5;
    color: #121212;
}
/* textarea,
input,
button {
    font-size: 1rem;
    font-family: inherit;
    border: none;
    border-radius: 8px;
    padding: 0.5rem 0.75rem;
    box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
    background-color: white;
    line-height: 1.5;
    margin: 0;
}
button {
    color: #3992ff;
    font-weight: 500;
}

textarea:hover,
input:hover,
button:hover {
    box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
}

button:active {
    box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.4);
    transform: translateY(1px);
}

#root {
    display: flex;
    height: 100%;
    width: 100%;
} */

#error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#emailInput::placeholder {
    color: #ff5b8d;
}
